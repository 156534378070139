@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Outfit', sans-serif;
}

.white path {
  fill: white;
}

button {
  font-family: 'Outfit';
}

::-webkit-scrollbar {
  display: none;
  width: 0;
}
div {
  scrollbar-width: 0;
  -ms-overflow-style: none;
}

@media screen and (max-width: 900px) {
  html,
  body {
    font-size: 14.5px;
  }
}
@media screen and (max-width: 400px) {
  html,
  body {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}

.custom-shape-divider-bottom-1707263755 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1707263755 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 210px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1707263755 .shape-fill {
  fill: #f8f7f7;
}
.custom-shape-divider-bottom-1707315090 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1707315090 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 229px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1707315090 .shape-fill {
  fill: #2f4dc4;
}
.custom-shape-divider-bottom-1707333217 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1707333217 svg {
  position: relative;
  display: block;
  width: calc(300% + 1.3px);
  height: 284px;
}

.custom-shape-divider-bottom-1707333217 .shape-fill {
  fill: #f8f7f7;
}
.blob {
  width: 180px;
  height: 180px;
  background: white;
  display: grid;
  place-content: center;
}
.blob1 {
  border-radius: 52% 48% 48% 52% / 37% 63% 37% 63%;
}
.blob2 {
  border-radius: 48% 52% 24% 76% / 47% 83% 17% 53%;
}
.blob3 {
  border-radius: 49% 51% 49% 51% / 21% 36% 64% 79%;
}
.blob4 {
  border-radius: 49% 51% 49% 51% / 75% 46% 54% 25%;
}
.blob5 {
  border-radius: 60% 40% 70% 30% / 39% 39% 61% 61%;
}
.blobicon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 1rem;
  gap: 1rem;
  /* background-color: chocolate; */
  /* width:  */
}
.blobsm {
  width: 90px;
  height: 90px;
  background: white;
  display: grid;
  place-content: center;
}
.blobsm:hover,
.blob:hover {
  cursor: pointer;
  background: #f8f7f7;
}
.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow {
  -moz-appearance: textfield;
}
